import React, {useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import './CaseStudy.scss';
import { Link } from 'gatsby';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import fadeInElementAnimation from '../../resources/fade-in-element-animation';

/**
 * Section is visible only for lg and above
 * @param additionalClasses
 * @param caseStudies
 * @param showSectionName
 * @param showCategories
 * @returns {JSX.Element}
 * @constructor
 */
const CaseStudy = ({ additionalClasses, caseStudies, showSectionName = true, showCategories }) => {
    const padStart = (value) => (value < 10 ? `0${value}` : `${value}`);

    const repeatN = (text, times) => (new Array(times))
        .fill(null)
        .map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={i}>{text}</span>
        ));
    const getCategorySlug = (category) => {
        switch (category) {
        case 'Case study':
        default:
            return 'case-study';
        case 'Media o nas':
            return 'media-o-nas';
        case 'Blog':
            return 'blog';
        }
    };

    // used to enable links to case study
    // to={`/${getCategorySlug(caseStudy.category)}/${caseStudy.slug}`}
    // add this to every Link tag in this file

    const mobileTag = caseStudies.map((caseStudy, index) => (
        <div className="case-study-small" key={`${caseStudy.id}-2`}>
            <Link
                to={`/${getCategorySlug(caseStudy.category)}/${caseStudy.slug}`}
                key={`${caseStudy.id}-3`}
                className="case-study-small__link"
            >
                <img
                    className="case-study-small__image"
                    alt={`GetHero case study ${caseStudy.name}`}
                    src={caseStudy.image[0].localFile.publicURL}
                />
                <div className="case-study-small__id">
                    {padStart(index + 1)}
                    {showCategories && (
                        <div className="case-study__category">
                            {caseStudy.category}
                        </div>
                    )}
                </div>
                <div className="case-study-small__name">
                    {caseStudy.name}
                </div>
                <div className="case-study-small__description">
                    {caseStudy.description}
                </div>
            </Link>

            <Link
                to={`/${getCategorySlug(caseStudy.category)}/${caseStudy.slug}`}
                key={`${caseStudy.id}-4`}
                className="case-study-small__link case-study-small__link--knowledge"
            >
                <img
                    className="case-study-small__image"
                    alt={`GetHero case study ${caseStudy.name}`}
                    src={caseStudy.image[0].localFile.publicURL}
                />
                <div className="case-study-small__info">
                    <div className="case-study-small__id">
                        {padStart(index + 1)}
                    </div>
                    <div className="case-study-small__name">
                        {caseStudy.name}
                    </div>
                    <div className="case-study-small__category">
                        {' '}
                        {showCategories && (
                            <span>
                                {caseStudy.category}
                            </span>
                        )}
                    </div>
                    <div className="case-study-small__description">
                        {caseStudy.description}
                    </div>
                </div>
            </Link>
        </div>
    ));
    const settings = !showCategories ? {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 100,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '18%',
    } : {};

    const caseStudiesRef = useRef(null);
    const caseStudiesMobileRef = useRef(null);
    const sectionName = useRef(null);

    fadeInElementAnimation([caseStudiesRef, caseStudiesMobileRef, sectionName]);

    return (
        <React.Fragment>
            <section className={['case-study-section d-none d-lg-block', additionalClasses].join(' ')}>
                {showSectionName && (
                    <div ref={sectionName} className="section-header">
                        <span className="section-name section-name--white">CASE STUDY</span>
                    </div>
                )}
                <div ref={caseStudiesRef} className="section-content">
                    {
                        caseStudies.map((caseStudy, index) => (
                            <Link
                                to={`/${getCategorySlug(caseStudy.category)}/${caseStudy.slug}`}
                                key={`${caseStudy.id}-1`}
                            >
                                <div className="case-study">
                                    <div className="case-study__id">
                                        {padStart(index + 1)}
                                        {showCategories && (
                                            <div className="case-study__category">
                                                {caseStudy.category}
                                            </div>
                                        )}
                                    </div>
                                    <div className="z-index-1">
                                        <div className="case-study__name">
                                            {caseStudy.name}
                                        </div>
                                        <div className="case-study__name case-study__name--alternative">
                                            {repeatN(caseStudy.name, 10)}
                                        </div>
                                        <div className="case-study__description">
                                            {caseStudy.description}
                                        </div>
                                    </div>
                                    <img
                                        className="case-study__image"
                                        alt={`GetHero case study ${caseStudy.name}`}
                                        src={caseStudy.image[0].localFile.publicURL}
                                    />
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </section>
            <section
                className={[
                    'case-study-section d-flex flex-column justify-content-center d-lg-none',
                    additionalClasses,
                ].join(' ')}
            >
                {showSectionName && (
                    <div className="section-header">
                        <span className="section-name section-name--white">CASE STUDY</span>
                    </div>
                )}
                <div ref={caseStudiesMobileRef} className="section-content">
                    {!showCategories ? (
                        <Slider {...settings}>
                            {mobileTag}
                        </Slider>
                    ) : <div>{mobileTag}</div>}
                </div>
            </section>
        </React.Fragment>
    );
};

export default CaseStudy;
